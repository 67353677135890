import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useHashScroll = (
    delay,
    offset = 0,
    behaviour = 'smooth',
    scrollType = 'normal'
) => {
    const router = useRouter();
    const scrollToHashElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            // Calculate the element's position relative to the viewport
            const elementPosition = element.getBoundingClientRect().top;
            // Scroll to the element using the `window.scrollTo` method

            // using for sticky header
            if (elementPosition < 0 && scrollType === 'bottom-to-top') {
                const stickyHeader = document.querySelector('#sticky-header');
                const stickyHeaderHeight = stickyHeader?.clientHeight || 80;
                window.scrollTo({
                    top: elementPosition + window.scrollY - stickyHeaderHeight - offset,
                    behavior: behaviour, // for smooth scrolling, or 'auto' for instant
                });
                return;
            }

            window.scrollTo({
                top: elementPosition + window.scrollY - offset,
                behavior: behaviour, // for smooth scrolling, or 'auto' for instant
            });
        }
    };

    const onHashChange = (hashId) => {
        setTimeout(() => {
            scrollToHashElement(hashId);
        }, delay);
    };

    useEffect(() => {
        const hashId = router.asPath.split('#')?.[1] || '';
        if (hashId) onHashChange(hashId);
    }, [router]);

    return {
        scrollToHashElement,
    };
};

export default useHashScroll;
