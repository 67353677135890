import React from 'react';

const Loading = ({ loadText = 'things', textVisible = true }) => {
    return (
        <div className="flex flex-col items-center justify-center w-full h-full">
            <div className="w-12 h-12 mb-4 border-b-4 rounded-full border-primary-600 animate-spin"></div>
            <div className="flex items-baseline ">
                {textVisible ? (
                    <>
                        <div className="mr-2 text-base font-semibold md:text-lg text-primary-500">
                            {`Getting your ${loadText} ready`}
                        </div>
                        <div
                            className="relative w-[6px] h-[6px] mr-1   rounded-full bg-primary-500 animate-bounce "
                            style={{ animationDelay: '100' }}
                        ></div>
                        <div
                            className="relative w-[6px] h-[6px] mr-1   rounded-full bg-primary-500 animate-bounce "
                            style={{ animationDelay: '200' }}
                        ></div>
                        <div
                            className="relative w-[6px] h-[6px] mr-1   rounded-full bg-primary-500 animate-bounce "
                            style={{ animationDelay: '300' }}
                        ></div>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default Loading;
