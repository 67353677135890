import * as OpportunityHelper from "@ui/helpers/opportunities/helper";
import { brandName } from './constants';
import { shuffleArray } from './miscelleanous';

export const bannerItems = [
    {
        text: 'high yields',
    },
    {
        text: 'alternative investments',
    },
    {
        text: 'fixed income',
    },
];

export const testimonialData = [
    {
        image: '/images/homepage/testimonials/sitakanta_ray.png',
        name: 'Sitakanta Ray',
        testimonial:
            '"altGraaf provides the platform to grow our wealth through fixed return assets. The ability to diversify across brands and asset types at one location provides a great peace of mind to me."',
        position: (
            <>
                Co-Founder <br /> RetainIQ
            </>
        ),
    },
    {
        image: '/images/homepage/testimonials/gitanjali_mirchandani.jpg',
        testimonial:
            '"I was super impressed with the ease of registering with the platform and investing using it. It gives you the confidence to invest and an opportunity to diversify your portfolio risk from the conventional debt and equity investments"',

        name: 'Gitanjali Mirchandani',
        position: <>Ex-MD JM Financial Credit Solutions (RE)</>,
    },
    {
        image: '/images/homepage/testimonials/rahul_chamaria.png',
        name: 'Rahul Chamaria',
        testimonial:
            ' "I am extremely satisfied with the alternate investment products offered by altGraaf, which otherwise not possible for small retail investors. altGraaf has bridged the gap and provided a tech-based solution to its investors"',
        position: (
            <>
                Executive Director <br /> Star Cement Ltd.
            </>
        ),
    },
    {
        image: '/images/homepage/testimonials/sawan_singamsetty.png',

        name: 'Sawan Singamsetty',
        testimonial:
            ' "Opportunities offered by altGraaf have great risk-return balance. The information provided is quite useful to help an an informed investment decision. I was very pleased with the straightforward, simple and clear process of investing."',
        position: (
            <>
                Associate Director <br /> HSBC Investment Banking
            </>
        ),
    },
    {
        image: '/images/homepage/testimonials/sumit_dhingra.jpg',

        name: 'Sumit Dhingra',
        testimonial:
            ' "altGraaf is my go-to place for alternate investments when it comes to fixed income instruments. I trust altGraaf with my money because I know that the team behind it is meticulous and thorough in its work process."',

        position: 'GM- India and South East Asia, Crocs',
    },
    {
        image: '/images/homepage/testimonials/ravi_handa.jpg',

        name: 'Ravi Handa',
        testimonial:
            ' "We need to look beyond FDs and debt funds if we want to beat inflation and altGraaf is the ideal platform. They have had some fantastic deals in the past and I hope they will continue to offer such deals in the future."',

        position: (
            <>
                Director
                <br />
                Unacademy
            </>
        ),
    },
    {
        image: '/images/homepage/testimonials/amit_arora.jpg',

        name: 'Amit Arora',
        testimonial:
            ' "I was a regular FD person earlier, so all my non-equity money was kept in FD. With 5% interest rate on FD, I was getting 3.5% post tax. However, altGraaf gives me opportunity to invest in alternative investment opportunities with much higher returns. Now I have a chance of beating inflation while keeping my investment secure."',

        position: (
            <>
                Associate Professor
                <br />
                IIT Gandhinagar
            </>
        ),
    },
    {
        image: '/images/homepage/testimonials/Shivi.jpg',

        name: 'Shivi Kalia',
        testimonial:
            ' "altGraaf has innovated the fixed income space in the form of new age alternative investments that provide very good returns with varied tenure. It’s been a safe investment option so far for me yielding returns better than other investment options available in the market. I continue to diversify my portfolio by investing in companies shortlisted by altGraaf."',
        position: (
            <>
                Ex-Head BHR <br /> Tech Mahindra Business Services
            </>
        ),
    },
    {
        image: '/images/homepage/testimonials/Shagun.jpg',

        name: 'Shagun Sahdev',
        testimonial:
            ' "altGraaf is a super convenient platform for investing in great opportunities. The opportunities are fixed in nature providing great returns with short tenure,  and it safeguards you from market volatility. With a million tabs open in our minds, we tend to keep investments at a lower priority while juggling multiple roles at home with the kids, and at work. Thank You altGraaf for empowering me and many of us to take investment decisions into our own hands."',

        position: <>Retail Consultant</>,
    },
];

export const brandUsp = [
    {
        id: 'higherReturns',
        title: 'Earn Higher Returns',
        titleMobile: 'Higher returns',
        content: {
            headline: 'Higher returns than traditional fixed income assets',
            footerText: `${brandName} provides you the opportunity to diversify your investments from traditional fixed income asset classes to help you earn higher returns`,
        },
    },
    {
        id: 'predictableReturns',
        title: 'Enjoy Predictable Returns',
        titleMobile: 'Predictable returns',
        content: {
            headline: 'Always know when your next payout is due; plan better',
            footerText: `Predictable returns help you plan for your goals better. ${brandName} provides you with opportunities that range from 30 days to 3 years. You can choose the tenures and pay out structures that suit your goals.`,
        },
    },
    {
        id: 'goals',
        title: 'One Platform for all Goals',
        titleMobile: 'One  platform',
        content: {
            headline: 'A single platform to achieve all your financial goals',
            footerText:
                'Invest across a range of fixed income asset classes that allow you to achieve all your financial goals on a single platform',
        },
    },
    {
        id: 'balancedPortfolio',
        title: 'Create a Balanced Portfolio',
        titleMobile: 'Balanced portfolio',
        content: {
            headline: 'Diversify your portfolio with non-market linked returns',
            footerText:
                'If all growth capital is in equity linked investments, any volatility can cause our portfolio to suffer dramatically. Create a balanced portfolio by ensuring that part of your investments earn non-market linked returns.',
        },
    },
];

export const recentDeals = [
    {
        logo: '/images/homepage/deals/Aris.svg',
        description:
            'The Infrastructure industry in India is growing – so is your money with Aris',
        name: 'Aris Infra',
        subscribed: '100% subscribed',
        opportunityName: 'Invoice Discounting',
        opportunityType: OpportunityHelper.getInvoiceDiscountingOpportunity(),
        grossReturns: '12.6%',
        tenure: '3 months',
    },
    {
        logo: '/images/homepage/deals/navi.svg',
        description: `Navi group is a financial services company.`,
        name: 'Navi',
        subscribed: '100% subscribed',
        opportunityName: 'Corporate Debt (unlisted)',
        opportunityType: OpportunityHelper.getCorporateDebtOpportunity(),
        grossReturns: '11.96 %',
        tenure: '24 months',
    },
    {
        logo: '/images/homepage/deals/caratlane.svg',
        description: `CP from a subsidiary of TITAN (TATA JV)`,
        name: 'CaratLane Trading Private Limited',
        subscribed: '100% subscribed',
        opportunityName: 'CommercialPaper',
        opportunityType: OpportunityHelper.getCorporateDebtOpportunity(),
        grossReturns: '7.5 %',
        tenure: '3 months',
    },
    {
        logo: '/images/homepage/deals/zetwerk.svg',
        description: `Zetwerk is a B2B manufacturing marketplace.`,
        name: 'Zetwerk',
        subscribed: '100% subscribed',
        opportunityName: 'Invoice Discounting',
        opportunityType: OpportunityHelper.getInvoiceDiscountingOpportunity(),
        grossReturns: '12.25 %',
        tenure: '3 months',
    },
    {
        logo: '/images/homepage/deals/1K.svg',
        description:
            '1K is bringing ecommerce to rural India, while helping you earn great returns',
        name: '1K Kirana Bazaar',
        subscribed: '100% subscribed',
        opportunityName: 'Invoice Discounting',
        opportunityType: OpportunityHelper.getInvoiceDiscountingOpportunity(),
        grossReturns: '15.9 %',
        tenure: '3 months',
    },
    {
        logo: '/images/homepage/deals/everest_fleet.svg',
        description:
            'Participate in Uber’s growth journey while earning monthly returns',
        name: 'Everest Fleet',
        subscribed: '100% subscribed',
        opportunityName: 'Asset Backed Leasing',
        opportunityType: OpportunityHelper.getAssetBackedLeasingOpportunity(),
        grossReturns: '17.5%',
        tenure: '36 months',
    },
    {
        logo: '/images/homepage/deals/Calpro.svg',
        description:
            'Invest in invoices of marquee FMCG clients like HUL & Nestle',
        name: 'Calpro',
        subscribed: '100% subscribed',
        opportunityName: 'Invoice Discounting',
        opportunityType: OpportunityHelper.getInvoiceDiscountingOpportunity(),
        grossReturns: '11.7%',
        tenure: '30 days',
    },
    {
        logo: '/images/homepage/deals/homecredit.svg',
        description:
            'A leading global consumer finance provider, helping you earn great returns',
        name: 'Home Credit',
        subscribed: '100% subscribed',
        opportunityName: 'Corporate Debt (unlisted)',
        opportunityType: OpportunityHelper.getCorporateDebtOpportunity(),
        grossReturns: '12%',
        tenure: '15 months',
    },
];

export const howToStartSteps = [
    {
        number: '01',
        title: 'Sign up to create your account.',
    },
    {
        number: '02',
        title: 'Enter ID proof, address proof, and bank details.',
    },
    {
        number: '03',
        title: 'Start exploring exclusive opportunities and invest.',
    },
];

export const ourInvestors = [
    {
        image: '/images/homepage/investors/Siddharth Shah.png',
        name: 'Siddharth Shah',
        position: 'Co-founder, Pharmeasy',
        cssClasses: 'lg:justify-start justify-end ',
        animateClass: 'animate__slideInRight',
        alt: 'siddharth shah cofounder of pharmeasy',
    },
    {
        image: '/images/homepage/investors/Mankekar.svg',
        name: 'Mankekar',
        position: 'Family Office',
        cssClasses: 'justify-start animate__delay-1s',
        animateClass: 'animate__slideInLeft',
        alt: 'Mankekar',
    },
    {
        image: '/images/homepage/investors/Capital A.svg',
        name: 'Capital - A',
        position: 'Venture Capital',
        cssClasses: 'lg:justify-start justify-end animate__delay-1s',
        animateClass: 'animate__slideInRight',
        alt: 'Capital - A',
    },
    {
        image: '/images/homepage/investors/Dharmil Sheth.png',
        name: 'Dharmil Sheth',
        position: 'Co-founder, Pharmeasy',
        cssClasses: 'justify-start animate__delay-2s',
        animateClass: 'animate__slideInLeft',
        alt: 'dharmil sheth cofounder of pharmeasy',
    },
    {
        image: '/images/homepage/investors/Ramakant Sharma.png',
        name: 'Ramakant Sharma',
        position: 'Co-founder, LivSpace',
        cssClasses: 'lg:justify-start justify-end animate__delay-3s',
        animateClass: 'animate__slideInRight',
        alt: 'ramakant sharma cofounder livspace',
    },
];

export const assetClasses = [
    {
        image: '/images/homepage/assetClasses/corporate-debt.svg',
        title: (
            <>
                Corporate Debt <span className="p5-medium">(unlisted)</span>
            </>
        ),
        irr: '10% - 16%',
        tenure: '12-36 months',
        link: `/products/unlisted-corporate-debt`,
    },
    {
        image: '/images/homepage/assetClasses/invoice-discounting.svg',
        title: 'Invoice Discounting',
        irr: '10% - 14%',
        tenure: '1-6 months',
        link: `/products/invoice-discounting`,
    },
    {
        image: '/images/homepage/assetClasses/asset-leasing.svg',
        title: 'Asset Leasing',
        irr: '14% - 22%',
        tenure: '24-48 months',
        link: `/products/asset-backed-leasing`,
    },
    {
        image: '/images/homepage/assetClasses/venture-debt.svg',
        title: 'Venture Debt',
        irr: '15% - 18%',
        tenure: '12-24 months',
        link: '/products/venture-debt',
    },
];

export const featuresList = [
    {
        title: 'Risk Assessed',
        value: 'The deals available on our platform undergo our rigorous and thorough internal credit and risk framework. ',
        dataAosProps: {
            'data-aos': 'fade-up',
            'data-aos-delay': '100',
        },
    },
    {
        title: 'Diversified Investments',
        value: 'Choose to invest in opportunities across multiple asset classes.',
        dataAosProps: {
            'data-aos': 'fade-up',
            'data-aos-delay': '500',
        },
    },
    {
        title: 'Ease of Investing',
        value: 'Easy to understand platform with insights and knowledge to build your customised portfolio.',
        dataAosProps: {
            'data-aos': 'fade-up',
            'data-aos-delay': '1000',
        },
    },
    {
        title: 'Secured deals',
        value: 'Access to opportunities that are backed by solid collateral or identified cashflows.',
        dataAosProps: {
            'data-aos': 'fade-up',
            'data-aos-delay': '1500',
        },
    },
];

export const PortfolioToggleButtons = [
    {
        title: 'Typical Portfolio',
    },
    {
        title: 'Balanced Portfolio',
    },
];

export const getFestiveBannerImages = (config) => {
    switch (config) {
        case 'BannerVersion 3.0':
            return [
                '/images/homepage/festive-banner-desktop.webp',
                '/images/homepage/festive-banner-mobile.webp',
            ];
        case 'BannerVersion 4.0':
            return [
                '/images/homepage/christmas-banner-desktop.webp',
                '/images/homepage/christmas-banner-mobile.webp',
            ];
        case 'BannerVersion 5.0':
            return [
                '/images/homepage/new-year-new-gain-banner-desktop.webp',
                '/images/homepage/new-year-new-gain-banner-mobile.webp',
            ];
        default:
            return [];
    }
};

export const brandDetails = [
    {
        id: 'altSmart',
        label: (
            <>
                Invest confidently with <br className="md:hidden" /> a bank
                guarantee
            </>
        ),
        logo: '/images/brands/altSmart.svg',
        irr: '10%-11%',
    },
    {
        id: 'altArmour',
        label: 'Balance risk with insurance protection',
        logo: '/images/brands/altArmour.svg',
        irr: '11%-12%',
    },
    {
        id: 'altBlu',
        label: 'Unlock growth with established companies',
        logo: '/images/brands/altBlu.svg',
        irr: '11%-12.5%',
    },
    {
        id: 'altPack',
        label: 'Accelerate returns through diversified investing',
        logo: '/images/brands/altPack.svg',
        irr: '10%-13%',
    },
    {
        id: 'altWings',
        label: 'Our Invoice Discounting product with returns as high as 14%',
        logo: '/images/brands/altWings.svg',
        irr: '12%-14%',
        highlights: [
            {
                label: 'successful deals',
                value: '1500+',
            },
            {
                label: 'repayments made',
                value: '₹3000 Cr+',
            },
        ],
    },
];

export const videoBannerConfigValue = 'BannerVersion 2.0';
export const festiveBannerConfigValues = [
    'BannerVersion 3.0',
    'BannerVersion 4.0',
    'BannerVersion 5.0',
];
export const brandingBannerConfigValue = 'BannerVersion 6.0';
export const bannerCarouselConfigValue = 'BannerVersion 7.0';

export const getFormattedHomepageBanners = (
    banners,
    isMobile,
    shouldShuffle = false
) => {
    if (shouldShuffle) shuffleArray(banners);

    return banners.map((banner) => {
        return {
            description: banner?.description || '',
            webLink: banner?.webLink,
            mobileLink: banner?.mobileLink,
            redirectLink: isMobile
                ? banner?.redirectAppLink
                : banner?.redirectWebLink,
            target: isMobile ? '_blank' : banner?.isNewTab ? '_blank' : '_self',
            gtmTrigger: banner?.gaTrigger1,
        };
    });
};




