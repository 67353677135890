import Image from "@atoms/Image";
import {appDownloadLink, mobileDeviceTypes} from "@helpers/constants";
import texts from '@molecules/Footer/V2/en.json';
import {Button} from "@ui/atoms/design-system";
import React, {useEffect, useState} from "react";
import DownloadAppQrCode from "@molecules/Footer/V2/DownloadAppQrCode";
import {getMobileOS} from "@helpers/utils";
import { triggerCustomEvent } from "@helpers/miscelleanous";

const BannerAppLinks = () => {

    const [showDownloadAppQrCode, setShowDownloadAppQrCode] = useState(null);
    const [isIos, setIsIos] = useState(null);
    const [mobileOs, setMobileOs] = useState(null);
    const onDownloadClick = ({ show, isIos }) => {
        if (show) {
            triggerCustomEvent(isIos ? 'ViewedIosQrBanner' : 'ViewedAndroidQrBanner');
        }
        setIsIos(isIos);
        setShowDownloadAppQrCode(show);
    };

    useEffect(() => {
        setMobileOs(getMobileOS());
    }, []);


    return (
        <div className={`flex flex-row gap-y-3 gap-x-6 items-center shrink-0`}>
            {/*for web/tab*/}
            <div className={`hidden md:flex items-center shrink-0 gap-x-2`}>
                <Button
                    prefixComponent={
                        <Image src='/images/footer/app_store_blue.svg' alt="app-store"/>
                    }
                    buttonType="outline"
                    buttonSize='extraSmall'
                    buttonText={texts?.GetIosApp}
                    onClick={() =>
                        onDownloadClick({
                            show: true,
                            isIos: true,
                        })
                    }
                />
                <Button
                    prefixComponent={
                        <Image src='/images/footer/google_play_blue.svg' alt="google-play"/>
                    }
                    buttonType="outline"
                    buttonSize='extraSmall'
                    buttonText={texts?.GetAndroidApp}
                    onClick={() =>
                        onDownloadClick({
                            show: true,
                            isIos: false,
                        })
                    }
                />
                {showDownloadAppQrCode && (
                    <DownloadAppQrCode
                        show={showDownloadAppQrCode}
                        isIos={isIos}
                        onClose={() =>
                            onDownloadClick({
                                qrImage: null,
                                isIos: null,
                            })
                        }
                    />
                )}
            </div>

            {/*for mobile */}
            <div
                className={`md:hidden`}>
                <a
                id={`gtm-click-banner-app-link-${mobileOs}`}
                    href={appDownloadLink} target='_blank'
                    className='cursor-pointer'>
                    <Button
                        prefixComponent={
                            <Image
                                src={mobileOs === mobileDeviceTypes.ios ? '/images/footer/app_store_blue.svg' :
                                    mobileOs === mobileDeviceTypes.android ? '/images/footer/google_play_blue.svg' :
                                        '/images/footer/download_app_blue.svg'
                                }
                                alt="store"/>
                        }
                        buttonType="outline"
                        buttonSize='extraSmall'
                        buttonText={texts?.DownloadTheApp}
                    />
                </a>
            </div>
        </div>
    );
};

export default BannerAppLinks;