import Image from '@atoms/Image';
import { getFestiveBannerImages } from '@helpers/homepageData';

function FestiveBanner({ onClick, config }) {
    const bannerImages = getFestiveBannerImages(config);
    return bannerImages?.length >= 2 ? (
        <section
            onClick={onClick}
            className="cursor-pointer mt-header-mobile md:mt-header w-full"
            id="gtm-click-banner-explore-now"
        >
            <Image
                src={bannerImages[0]} // desktop
                className="w-full h-full md:block hidden"
            />
            <Image
                src={bannerImages[1]} // mobile
                className="w-full h-full md:hidden block"
            />
        </section>
    ) : null;
}
export default FestiveBanner;
