import {useEffect, useState} from 'react';
import 'animate.css/animate.min.css';
import dynamic from 'next/dynamic';
const LoginModal = dynamic(() => import('@components/Auth/LoginModal'));
const Stats = dynamic(() => import('@components/Homepage/Stats'));
import StructuredData from '@components/StructuredData';
import { homePageStructuredData } from '@helpers/constants';
const Opportunities = dynamic(() =>
    import('@components/Homepage/Opportunities')
);
const AssetClasses = dynamic(() => import('@components/Homepage/AssetClasses'));
const BrandInfo = dynamic(() => import('@components/Homepage/BrandInfo'));
const BrandUSP = dynamic(() => import('@components/Homepage/BrandUSP'));
const Features = dynamic(() => import('@components/Homepage/Features'));
const RecentDeals = dynamic(() => import('@components/Homepage/RecentDeals'));
const StartInvestingStep = dynamic(() =>
    import('@components/Homepage/startInvestingStep')
);
const Investors = dynamic(() => import('@components/Homepage/Investors'));
const Testimonials = dynamic(
    () => import('@components/Homepage/Testimonials'),
    {
        ssr: false, // because of Hydration issue
    }
);
const VideoBanner = dynamic(() => import('@components/Homepage/VideoBanner'));
const Banner = dynamic(() => import('@components/Homepage/Banner'));
const CarouselBanner = dynamic(() =>
    import('@components/Homepage/CarouselBanner')
);
const FooterV2 = dynamic(() =>
    import('@molecules/Footer/V2/Footer')
);

import {
    getAuth,
    setReferralCode,
    setReferralEntityType,
} from '@services/identity.service';
import { useRouter } from 'next/router';
import NavHeader from '@molecules/NavHeader';
import { getOpenConfigurationByEntityType } from '@services/configuration.service';
import { useInvestorContext } from '@context/InvestorContextProvider';
import { investmentStats } from '@services/investment-stats.service';
import FestiveBanner from '@components/Homepage/FestiveBanner';
import NextHeadTag from '@ui/molecules/NextHeadTag';
import { HeadTagData } from '@helpers/headTagData';
import {
    bannerCarouselConfigValue,
    brandingBannerConfigValue,
    featuresList,
    festiveBannerConfigValues,
    videoBannerConfigValue,
} from '@helpers/homepageData';
import texts from '@components/Homepage/en.json';
import { Button } from '@ui/atoms/design-system';
import Text from '@atoms/Text';
import { isValidResponseEntity } from '@helpers/utils';
import DownloadAppBanner from '@components/Homepage/Banner/DownloadAppBanner';
import useHomepageActivities from '@hooks/functional/useHomepageActivities';
import { getHomepageBanners } from '@services/homepage.service';
import { cn } from '@ui/helpers/utils';
import useHashScroll from '@hooks/useHashScroll';
import BottomNavigation from '@molecules/BottomNavigation';
import bottomNavigationTexts from "@molecules/BottomNavigation/en.json";
import {logEvent, moengageEventConstants} from "@services/moengage";

function Homepage({
    configuration,
    statsResponse,
    festiveSeason,
    homepageBanners,
}) {
    const auth = getAuth();
    useHashScroll(100, 0, 'smooth', 'bottom-to-top');
    const { menu, menuLoading, investorCache } = useInvestorContext();
    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(!!auth);
    const router = useRouter();
    const [isVideoCoverVisible, setIsVideoCoverVisible] = useState(true);
    const isVideoBannerActive = configuration?.value === videoBannerConfigValue;
    const isFestiveBannerActive = festiveBannerConfigValues.includes(
        configuration?.value
    );
    const showBottomNavigation = bottomNavigationTexts?.hideBottomNavOnFirstLevelPages.indexOf(router?.pathname) === -1;
    const [isBottomNavVisible, setIsBottomNavVisible] = useState(false);

    const brandingBannerActive =
        configuration?.value === brandingBannerConfigValue;

    const bannerCarouselActive =
        configuration?.value === bannerCarouselConfigValue &&
        homepageBanners?.length > 0;

    useEffect(() => {
        logEvent({eventName: moengageEventConstants.VIEWED_HOME_PAGE,});
    }, []);

    const showSignupModal = () => {
        handleMenuClicked('SignUp');
    };

    const onLoginModalClose = () => {
        setShowLogin(false);
        setShowSignUp(false);
        setShowEmailInput(false);
    };

    const handleMenuClicked = (menu) => {
        let actions = {
            Login: () => {
                setShowSignUp(false);
                isLoggedIn ? router.push('/opportunities') : setShowLogin(true);
            },
            SignUp: () => {
                if (isLoggedIn) {
                    router.push('/opportunities');
                } else {
                    setShowLogin(true);
                    setShowSignUp(true);
                    setShowEmailInput(true);
                }
            },
        };
        let handler = actions[menu];
        if (handler) handler();
    };

    useHomepageActivities({
        auth,
        router,
        setIsLoggedIn,
        setReferralCode,
        setReferralEntityType,
        supportsIntersectionObserver,
        setShowLogin,
    });

    return (
        <>
            <NextHeadTag {...HeadTagData?.['/']} />
            <StructuredData data={homePageStructuredData} />
            <div className={`overflow-hidden homepage-wrapper ${isBottomNavVisible ? 'pb-[70px] xl:pb-0' : 'pb-0'}`} id="pagesindex">
                <NavHeader
                    hideHeaderOnScroll={true}
                    menuClicked={handleMenuClicked}
                    translucent={false}
                    homePage={true}
                    menu={menu}
                    menuLoading={menuLoading}
                    investorCache={investorCache}
                    festiveSeason={festiveSeason}
                />
                <div id="homepageWrapper">
                    <div
                        className={cn('relative flex flex-col', {
                            'relative flex flex-col items-center justify-center m-auto':
                                !bannerCarouselActive,
                        })}
                    >
                        {isVideoBannerActive ? (
                            <VideoBanner
                                isCoverVisible={isVideoCoverVisible}
                                setIsCoverVisible={setIsVideoCoverVisible}
                            />
                        ) : isFestiveBannerActive ? (
                            <FestiveBanner
                                onClick={showSignupModal}
                                config={configuration?.value}
                            />
                        ) : brandingBannerActive ? (
                            <BrandInfo />
                        ) : bannerCarouselActive ? (
                            <CarouselBanner homepageBanners={homepageBanners} />
                        ) : (
                            <Banner onButtonClick={showSignupModal} />
                        )}
                        {/*Note : if changing class of this component pl update bottom component too*/}
                        {(bannerCarouselActive || !isVideoCoverVisible) ? null : (
                            <div
                                className={`${
                                    isFestiveBannerActive
                                        ? 'relative xl:mt-0 mt-6 xl:absolute xl:-bottom-[92px]'
                                        : 'absolute -bottom-[228px] md:-bottom-[168px] xl:-bottom-[168px]'
                                } md:z-50 w-full max-w-screen-maxScreen mx-auto px-4 md:px-6 xl:px-20`}
                            >
                                <div className="relative w-full h-20 bg-download-app-card-bg rounded-t-3xl md:rounded-t-2xl -bottom-20" />
                                <div className="relative">
                                    <DownloadAppBanner />
                                    <Stats
                                        statsResponse={statsResponse}
                                        className="relative md:z-10 bg-primary-500 rounded-3xl md:rounded-2xl"
                                        paddingClass="p-6 xl:px-2 xl:py-10"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div
                        className="w-full px-4 py-6 mx-auto md:px-6 max-w-screen-maxScreen xl:px-20"
                        id="investmentsStats"
                    >
                        <Stats
                            statsResponse={statsResponse}
                            className="relative md:z-10 bg-primary-20 rounded-3xl md:rounded-2xl"
                            paddingClass="p-6 xl:px-2 xl:py-10"
                            isHomepage
                        />
                    </div>

                    <div
                        className={
                            isFestiveBannerActive || bannerCarouselActive
                                ? ''
                                : 'mt-[86px] md:mt-[88px] xl:mt-[76px]'
                        }
                    >
                        <Opportunities
                            isCustomBannerVisible={
                                (!isVideoCoverVisible && isVideoBannerActive) ||
                                isFestiveBannerActive
                            }
                            bannerCarouselActive={bannerCarouselActive}
                        />
                    </div>
                    <AssetClasses />
                    <BrandUSP />
                    <Features
                        featuresList={featuresList}
                        primaryText={
                            <>
                                <Text
                                    content={texts?.FeaturesPrimaryText}
                                    isInnerHtml
                                />
                            </>
                        }
                        primaryFonts="h5-regular md:h4-regular xl:h3-regular"
                        breakpoint="xl"
                    />
                    <StartInvestingStep onButtonClick={showSignupModal} />
                    <RecentDeals />
                    <Investors
                        bgAndBorderColor="bg-secondary-10 border-secondary-50"
                        paddingClass="pt-8 md:pt-24"
                    />
                    <div className="mx-auto overflow-hidden max-w-screen-maxScreen">
                        <Testimonials paddingClass="py-12 md:pt-28 md:pb-[68px]" />

                        <Button
                            buttonType="primary"
                            onClick={showSignupModal}
                            className="z-50 mx-auto mb-12 md:mb-[68px]"
                            buttonSize={'small'}
                            buttonText="Explore Opportunities"
                        />
                    </div>
                    <FooterV2 />
                </div>
            </div>
            {(showLogin || showSignUp) && (
                <LoginModal
                    show={showLogin}
                    showLogin={showLogin}
                    showSignUp={showSignUp}
                    showEmail={showEmailInput}
                    onClose={onLoginModalClose}
                    setShowSignUp={setShowSignUp}
                    setShowLogin={setShowLogin}
                />
            )}

            {investorCache && showBottomNavigation ? (
                <BottomNavigation
                    investorCache={investorCache}
                    visible={true}
                    setIsBottomNavVisible={setIsBottomNavVisible}
                />
            ) : null}
        </>
    );
}

function supportsIntersectionObserver() {
    return !!(
        'IntersectionObserver' in window ||
        'IntersectionObserverEntry' in window ||
        'intersectionRatio' in window.IntersectionObserverEntry.prototype
    );
}

export default Homepage;

export const getStaticProps = async (ctx) => {
    const defaultConfig = {
        type: 'General',
        entityType: 'HomePageBanner',
        value: 'Default',
    };
    const statsResponse = await investmentStats(ctx);
    const investorstatsCacheTime = parseInt(
        process.env.NEXT_PUBLIC_INVESTOR_STATS_CACHE_DURATION
    );
    try {
        const response = await getOpenConfigurationByEntityType(
            'HomePageBanner',
            ctx
        );

        let formattedHomepageBanners = [];
        if (
            isValidResponseEntity(response) &&
            response?.entity?.value === bannerCarouselConfigValue
        ) {
            const homepageBanners = await getHomepageBanners(ctx);
            if (isValidResponseEntity(homepageBanners)) {
                formattedHomepageBanners = homepageBanners?.entity;
            }
        }

        const configuration = response.entity || defaultConfig;
        return {
            props: {
                configuration,
                statsResponse,
                homepageBanners: formattedHomepageBanners,
            },
            revalidate: investorstatsCacheTime,
        };
    } catch (error) {
        return {
            props: { configuration: defaultConfig, statsResponse },
            revalidate: investorstatsCacheTime,
        };
    }
};
