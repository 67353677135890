export const ifaMasterCommisionPercentage = 1;
export const brandName = 'altGraaf';
export const WalletName = 'AltSafe';
export const careEmail = 'care@altgraaf.com';
export const supportEmail = 'care@altgraaf.com';
export const partnersEmail = 'partners@altgraaf.com';

export const navbarMenu = [{ title: 'All investors', url: '/dashboard' }];
export const menu = [
    {
        labelName: 'All investors',
        iconSrc: '/images/darkProfile.svg',
        linkSrc: '/dashboard',
    },
    {
        labelName: 'Onboard Investor',
        iconSrc: '/images/addUser.svg',
        linkSrc: '/onboard-investor',
    },
];

export const panAlreadyExistMessage = `The PAN you have entered is already associated with another account on ${brandName}. You cannot have the same PAN associated with more than one account with us.`;

export const addressProofTypes = [
    { name: 'Aadhar Card', id: 'Aadhar' },
    { name: 'Voter Id', id: 'VoterId' },
    { name: 'Passport', id: 'Passport' },
];

export const maxFileSize = 2000;

export const INVESTOR_KYC_BUCKET = process.env.NEXT_PUBLIC_INVESTOR_KYC_BUCKET;

export const getHeaderAndDescriptionForDoubleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description: 'Your Details should be clearly readable',
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description: 'Your Details should be clearly readable',
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description: 'Your Details should be clearly readable',
            };
        default:
            break;
    }
};

export const getDoubleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                front: {
                    header: 'Upload front side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-front.png',
                },
                back: {
                    header: 'Upload back side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-back.png',
                },
            };
        case 'VoterId':
            return {
                front: {
                    header: 'Upload front side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-front.png',
                },
                back: {
                    header: 'Upload back side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-back.png',
                },
            };
        case 'Passport':
            return {
                front: {
                    header: 'Upload Photo page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-front.png',
                },
                back: {
                    header: 'Upload Address page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-back.png',
                },
            };
        default:
            break;
    }
};

export const getSingleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                defaultImageUrl: '/images/investors/kyc/aadhar-full.png',
            };
        case 'VoterId':
            return {
                defaultImageUrl: '/images/investors/kyc/voterId-full.png',
            };
        case 'Passport':
            return {
                defaultImageUrl: '/images/investors/kyc/passport-full.png',
            };
        default:
            break;
    }
};

export const getSingleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: 'If you have a two seperate images for Aadhar and wish to upload each side separately, click',
        VoterId:
            'If you have a two seperate images for Voter ID and wish to upload each side separately, click',
        Passport:
            'If you have a two seperate images for Passport and wish to upload each side separately, click',
    };
    return addressTypes[addressType];
};

export const getDoubleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: "Don't have a two-sided for Aadhaar?\nTo upload a single page Aadhaar click",
        VoterId:
            "Don't have a two seperated images for Voter ID?\nTo upload a single file of Voter ID click",
        Passport:
            "Don't have a two seperated images for Passport?\nTo upload a single file of Passport click",
    };
    return addressTypes[addressType];
};

export const allowedFileUploads = 'image/png, image/jpeg, image/jpg';

export const getHeaderAndDescriptionForSingleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        default:
            break;
    }
};

export const DEFAULT_TAGS_SIZE = 4;

export const otpLength = 6;

/*
 * using this for htmlContent fields
 * font-bold -> font-semibold
 * <b> -> ''
 * </b> -> ''
 * <th class="border-r-2 px-4 py-2 -> <th class="border-r-2 px-4 py-2 font-medium
 *
 * will remove this in future when we will migrate the html data
 */
export const keyContentReplacedPairs = [
    ['font-bold', 'font-medium'],
    ['<b>', ''],
    ['</b>', ''],
    [
        '<th class="border-r-2 px-4 py-2',
        '<th class="border-r-2 px-4 py-2 font-medium',
    ],
];

export const DEFAULT_VALUE = '-';

export const otpExpiredErrorWord = 'expired';

export const riskCategoryVariantData = {
    veryLow: {
        class: 'text-semantic-success-base',
        image: '/images/riskCategory/very-low.svg',
        label: 'Very Low',
    },
    low: {
        class: 'text-semantic-olive-base',
        image: '/images/riskCategory/low.svg',
        label: 'Low',
    },
    medium: {
        class: 'text-semantic-yellow-base',
        image: '/images/riskCategory/medium.svg',
        label: 'Medium',
    },
    high: {
        class: 'text-semantic-warning-base',
        image: '/images/riskCategory/high.svg',
        label: 'High',
    },
    veryHigh: {
        class: 'text-semantic-error-base',
        image: '/images/riskCategory/very-high.svg',
        label: 'Very High',
    },
};

export const maxNameLength = 100;