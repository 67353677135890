import React from "react";
import texts from "@molecules/Footer/V2/en.json";
import Text from "@atoms/Text";
import BannerAppLinks from "@components/Homepage/Banner/BannerAppLinks";
import Image from "@atoms/Image";
import {motion} from "framer-motion";

const DownloadAppBanner = () => {
    return (
        <div className="md:flex hidden flex-row justify-center items-end">
            <div
                className="relative py-3 flex flex-row justify-center items-center gap-x-2 md:gap-x-6 left-14 md:left-16">
                <motion.div
                    className="absolute -top-[74px] -left-28  md:-left-32"
                    initial={{y: '100%', display: 'none'}}
                    animate={{y: '0%', display: 'block'}}
                    transition={{
                        delay: 1.0,
                        duration: 0.8,
                        type: "spring",
                        stiffness: 100,
                        damping: 15,
                        mass: 1,
                    }}
                >
                    <Image
                        src={'/images/footer/phone.png'}
                        alt="app-store"
                        width="116"
                        height="128"
                    />
                </motion.div>
                <Text htmlTag="h2" className="hidden xl:block text-primary-500 p4-medium"
                      content={
                          <>{texts?.InvestmentsMadeEasyWithApp}
                              <span className='p4-semibold'>{texts?.AltGraafApp}</span>
                          </>
                      }>
                </Text>
                <Text
                    className="hidden md:block xl:hidden text-primary-500 p4-semibold"
                      content={texts?.DownloadTheAltGraafApp}
                >
                </Text>
                <BannerAppLinks/>
            </div>
        </div>
    );
};

export default DownloadAppBanner;
